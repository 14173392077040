import i18nzh from '../../../config/locale/zh-CN.json';
import i18nen from '../../../config/locale/en-US.json';
import i18ntw from '../../../config/locale/zh-TW.json';

var I18n = {
	getText:function (key) {
		var data = this.data;
		return data[key] || "";
	},
	data:null,
	init:function () {
		var locale = this.getCookie("locale");
		if(locale.indexOf("zh") >= 0){
			this.data = i18nzh;
		}else if(locale.indexOf("tw") >= 0){
			this.data = i18ntw;
		}else{
			this.data = i18nen;
		}
	},
	getCookie:function (key) {
		if (document.cookie.length > 0){
			var c_start = document.cookie.indexOf(key + "=");
			if (c_start != -1){
				c_start = c_start + key.length + 1;
				var c_end = document.cookie.indexOf(";", c_start);
				if (c_end == -1) {
					c_end=document.cookie.length;
				}
				return unescape(document.cookie.substring(c_start, c_end));
			}
		}
		return "en"
	}
};
//初始化国际化代码
I18n.init();
module.exports = I18n;