import Utils from "../common/util";
import I18n from "../common/i18n";
var Page = {
    init:function () {
        this.server.isLogined();
        this.i18n.changeLanguage();
    },
    server:{
        isLogined:function () {
            var user = localStorage.getItem("user");
            var con = $(".button-group");
            var html = '<a href="/login" class="button">'+ I18n.getText('global.login') +'</a><a href="/signup" class="button blue ml10">'+ I18n.getText('global.signup') +'</a>';
            if(user == null){
                con.append(html);
            }else{
                //检查token是否有效
                Utils.post("/api/user/getconfig", {}, true).then(function (data) {
                    //token正常
                    if(data && data.code == 0 && data.data.config){
                        var mapId = data.data.config.mapId;
                        if(mapId == null){
                            mapId = "new";
                        }
                        var url = "/map/" + mapId;
                        html = '<a href="'+ url +'" class="button blue" style="font-size:14px;">'+ I18n.getText('home.go') +'</a>';
                        $("#getstart").attr("href", url)
                    }
                    con.append(html);
                });
            }
        }
    },
    i18n:{
        changeLanguage:function () {
            var that = this;
            $(".language").find("li").off("").on("click", function (e) {
                var tit = $(this).attr("tit");
                that.setCookie("locale", tit);
                location.reload();
            });
            var locale = that.getCookie("locale");
            if(locale == "zh-cn"){
                $(".language").find("label").text("简体中文")
            }else if(locale == "zh-tw"){
                $(".language").find("label").text("繁体中文")
            }else{
                $(".language").find("label").text("English")
            }
        },
        setCookie:function(name, value){
            var Days = 365;
            var exp = new Date();
            exp.setTime(exp.getTime() + Days*24*60*60*1000);
            document.cookie = name + "="+ escape (value) + ";expires=" + exp.toGMTString();
        },
        getCookie:function(name){
            var arr,reg=new RegExp("(^| )"+name+"=([^;]*)(;|$)");
            if(arr=document.cookie.match(reg))
                return unescape(arr[2]);
            else
                return null;
        }
    }
};
Page.init();