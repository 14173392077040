
var Utils = {};
Utils.post = function (url, data, useToken, failTip) {
	var root = window.baseUrl;
	var params = {
		url: root + url,
		data: data,
		type: 'post',
		dataType: 'json',
		cache: false,
		crossDomain:true, //支持跨域
		timeout: 60000 // 45秒超时
	};
	if(useToken){
		var tokenObj = Utils.getTokenFromLocal();
		if(tokenObj == null){
			//记录url
			var url = location.href;
			localStorage.setItem("url", url);
			location.href = "/login";
			return;
		}
		var token = tokenObj.token;
		params["beforeSend"] = function(req){
			req.setRequestHeader('Authorization', token);
		};
	}
	//上传附件的特殊处理
	if(url.indexOf("/api/files/upload") >= 0){
		delete params["dataType"];
		params["processData"] = false;
		params["contentType"] = false;
	}
	var deferred = $.Deferred();
	$.ajax(params).fail(function (data) {
		if (failTip !== false) {
			// 没有禁用自动失败提示
			console.log('请求处理失败，请稍后尝试。');
		}
		deferred.resolve();

	}).then(function (response) {
		var code = response.code;
		if (code == 0 || code == 1) {
			//记录url
			var url = location.href;
			if(code == 1 && response.msg == "user" && url.indexOf("api") >= 0){
				localStorage.setItem("url", url);
				location.href = "/login";
				return;
			}
			// 成功
			deferred.resolve(response);
		}else if (code === 2) {
			// 登录过期
			var msg = response.msg;
			if (msg) {
				Utils.confirm({
					title: '请登录',
					content: msg
				}).then(function () {
					$.loginWindow();
				});
			}
			deferred.reject(response);
		}
	});
	return deferred;
};

Utils.confirm = function(options){
	if(typeof options == "string"){
		options = {
			content:options
		};
	}
	options = $.extend({
		title:"请确认",
		oktext:"确定",
		canceltext:"取消"
	}, options);

	var html = $('<div class="dlg" id="confirm_dlg">' +
		'<h3>'+ options.title +'</h3>' +
		'<div class="dlg-content">'+ options.content +'</div>' +
		'<div class="dlg-btns">' +
		'<span close-btn="true" class="button default">'+ options.canceltext +'</span>' +
		'<span ok-btn="true" class="button red">'+ options.oktext +'</span>' +
		'</div>').appendTo("body");
	html.dlg();
	html.find("[ok-btn]").off("click").on("click", function(){
		if(options.onConfirm != null){
			options.onConfirm();
			html.dlg("close");
			html.remove();
		}
	});
};
Utils.loading = function(){
	$(".loading").remove();
	var html = $('<div class="loading"><i class="icons rotate1">&#xe64d;</i></div>');
	html.appendTo("body");
}
Utils.stopLoading = function(){
	$(".loading").remove();
}
Utils.saveTokenToLocal = function(userInfo){
	var userStr = JSON.stringify(userInfo);
	localStorage.setItem("user", userStr);
}
Utils.getTokenFromLocal = function(){
	return JSON.parse(localStorage.getItem("user"));
}
module.exports = Utils;
